import { useState } from 'react';
import { useComisionService } from '../../../../service/comision';
import { useComisiones } from '../useComisiones';
import { errorMessages } from '../../../../components/messages/commonMessages';

export const useFormulario = (onSave, onCancel, comision, render) => {
    const [descripcion, setDescripcion] = useState(comision !== '' ? comision[0].dscComision : '');
    const [codigoInterno, setCodigoInterno] = useState(comision !== '' ? comision[0].codComision : '');
    const { post, put } = useComisionService();
    const { getComisiones } = useComisiones();

    let values = {};
    const addComisiones = async (values) => {
        post(values);
    }
    const updateComisiones = async (idComision, values) => {
        put(idComision, values);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (descripcion && codigoInterno) {
            values.dscComision = descripcion;
            values.codComision = codigoInterno;
            values.indActivo = 1;
            values.usuCreacion = "maria.sanchez";
            if (render === 'Add') {
                await addComisiones(values);
                await getComisiones();

            } else {
                let idComision = comision[0].idComision
                if (idComision !== undefined) {
                    await updateComisiones(idComision, values);
                    await getComisiones();
                }
            }
            onSave();
            setDescripcion('');
            setCodigoInterno('');
        } else {
            alert(errorMessages.FORM_ERROR);
        }
    };
    const updateCodigoInterno = async (value) => {
        setCodigoInterno(value)
    }
    const updateDescripcion = async (value) => {
        setDescripcion(value)
    }
    return (
        { updateDescripcion, updateCodigoInterno, handleSubmit, descripcion, codigoInterno }
    )
};