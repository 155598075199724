import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CircularProgress from '@mui/material/CircularProgress';

//Paginación de la tabla de detalle

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="página anterior"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="siguiente página"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

//Creación de tabla

const SubTable = ({ columns, data, filter, onSubRowSelect, selectedValues }) => {
    const subRowData = data;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subRowData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSubRowSelect = (subRow) => {
        onSubRowSelect(subRow);
    };

    return (
        <Table size="small" aria-label="sub-table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(rowsPerPage > 0
                    ? subRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : subRowData
                ).map((subRow, index) => (
                    <TableRow key={index} onClick={() => handleSubRowSelect(subRow)}>
                        {columns.map((column) => (
                            <TableCell key={column.id} >{column.id === 'accion' ? column.renderCell({ subRow }) : subRow[column.id]}</TableCell>
                        ))}
                    </TableRow>
                ))}

                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={columns.length} />
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todo', value: -1 }]}
                        colSpan={columns.length}
                        count={subRowData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={"Filas por página:"}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'filas por página:',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
};

const Row = ({ row, columns, subColumns, subRows, onRowSelect, onSubRowSelect, selectedValues, expandedRow, onRowClick }) => {

    const open = expandedRow === row.id;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setIsLoading(false);
        };

        if (open) {
            loadData();
        }
    }, [open]);

    return (
        <>
            <TableRow onClick={() => onRowSelect(row)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => onRowClick(row.id)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {columns.map((column) => (
                    <TableCell key={column.id}>
                        {column.id === 'accion' ? column.renderCell({ row }) : row[column.id]}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {isLoading ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ padding: "20px" }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : subRows && subRows.length > 0 ? (
                                <SubTable
                                    columns={subColumns}
                                    data={subRows}
                                    filter={row}
                                    onSubRowSelect={onSubRowSelect}
                                    selectedValues={selectedValues}
                                />
                            ) : (
                                <div
                                    style={{
                                        textAlign: "center",
                                        padding: "10px",
                                        fontStyle: "italic",
                                        color: "#888",
                                    }}
                                >
                                    No se encontraron registros
                                </div>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const CollapsibleTable = ({ columns, subColumns, rows, subRows, onSubRowSelect, onRowSelect }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (id) => {
        setExpandedRow((prev) => (prev === id ? null : id));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowSelect = (selectedRow) => {
        onRowSelect([selectedRow.idComision]);
    };

    const handleSubRowSelect = (subRow) => {
        onSubRowSelect([subRow.idComisionTip]);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {columns.map((column) => (
                            <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
                                No se encontraron registros
                            </TableCell>
                        </TableRow>
                    ) : (
                        (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <Row
                                key={row.id}
                                row={row}
                                columns={columns}
                                subColumns={subColumns}
                                subRows={subRows}
                                onRowSelect={handleRowSelect}
                                onSubRowSelect={handleSubRowSelect}
                                expandedRow={expandedRow}
                                onRowClick={handleRowClick}
                            />
                        ))
                    )}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Todo', value: -1 }]}
                            colSpan={columns.length + 1}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"Filas por página:"}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'filas por página:',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export { CollapsibleTable };
