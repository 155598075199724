import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { successMessages, errorMessages } from '../../messages/commonMessages.jsx';
import { useComisionService } from '../../../service/comision.js';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDetalleComisiones } from './useDetalleComisiones.js';

export const useComisiones = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(null);
    const { get, patch } = useComisionService();

    const { handleSubRowSelectionChange, getDetallesComisiones, handleSubSave, handleSubCloseDialog, subColumns,
        isSubDialogDialogOpen, isSubEdit, isSubView, isSubAdd, subAlert, handleSubAdd, handleDeleteDetalle, handleSubCancel,
        isSubDelete, handleSelectedIdRow, selectedSubRow, detalle } = useDetalleComisiones();

    const getComisiones = async () => {
        get().then((response) => {
            if (Array.isArray(response)) {
                setData(response);
            } else {
                console.error("Error: Los datos no son un arreglo");
                showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
            }
        }).catch((error) => {
            console.error("Error al obtener comisiones:", error);
            showAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
        });
    }

    const deleteComisiones = async (idComision, values) => {
        patch(idComision, values);
    }

    const handleSave = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        getComisiones();
        showAlert("success", "Éxito", successMessages.CHANGES_SAVED);
    };
    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };
    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };
    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };
    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };
    const handleDeleteData = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsDelete(false);
        deleteComisiones(selectedRow[0].idComision, selectedRow)
            .then(() => {
                return getComisiones();
            })
            .catch((error) => {
                console.error("Error al eliminar o recargar datos:", error);
                showAlert("error", "Error", errorMessages.DELETE_ERROR);
            });
        getComisiones();
        showAlert("success", "Éxito", successMessages.DELETE_SUCCESSFUL);
    };

    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRow = data.filter((row) =>
            selectedIDs.has(row.idComision),
        );
        setSelectedRow(selectedRow);
        if (selectedRow.length > 0) {
            console.log(selectedRow[0].dscComision);
            getDetallesComisiones(selectedRow[0].idComision);
            handleSelectedIdRow(selectedRow[0].idComision);
        }
    };

    const columns = [
        { id: 'fecCreacion', label: 'Fecha Registro' },
        { id: 'codComision', label: 'Código' },
        { id: 'dscComision', label: 'Descripción' },
        {
            id: 'accion', label: 'Acciones',
            renderCell: (params) => (
                <div className='button-action'>
                    <button className="action-button" onClick={handleConsult}>
                        <SearchIcon />
                    </button>
                    <button className="action-button" onClick={handleEdit}>
                        <EditIcon />
                    </button>
                    <button className="action-button" onClick={handleDelete}>
                        <DeleteIcon />
                    </button>
                    <button className="action-button" onClick={handleSubAdd}>
                        <AddIcon />
                    </button>
                </div>
            ),
        },
    ];

    return (
        {
            handleRowSelectionChange, getComisiones, handleSave, handleCloseDialog, columns, isDialogOpen,
            isEdit, isView, isAdd, alert, handleAdd, handleCancel, handleDeleteData, isDelete, selectedRow, data,
            handleSubRowSelectionChange, getDetallesComisiones, handleSubSave, handleSubCloseDialog, subColumns,
            isSubDialogDialogOpen, isSubEdit, isSubView, isSubAdd, subAlert, handleSubAdd, handleDeleteDetalle, 
            handleSubCancel, isSubDelete, selectedSubRow, detalle
        }
    )
};