import { useState } from 'react';
import { useCodigoService } from '../../../../service/codigo';
import { useCodigoRespuesta } from '../useCodigoRespuesta';

export const useFormulario = (onSave, onCancel, codigo, render) => {
    const [codigoRespuesta, setCodigoRespuesta] = useState(codigo !== '' ? codigo[0].codRespuesta : '');
    const [descripcion, setDescripcion] = useState(codigo !== '' ? codigo[0].dscCodRespuesta : '');
    const { post, put } = useCodigoService();
    const { getCodigos } = useCodigoRespuesta();

    let values = {};
    const addCodigos = async (values) => {
        post(values);
    }
    const updateCodigos = async (idCodigo, values) => {
        put(idCodigo, values);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (codigoRespuesta && descripcion) {
            values.dscCodRespuesta = descripcion;
            values.codRespuesta = codigoRespuesta;
            values.indActivo = 1;
            if (render === 'Add') {
                await addCodigos(values);
                await getCodigos();

            } else {
                let idCodigo = codigo[0].idCodRespuesta;
                if (idCodigo !== undefined) {
                    await updateCodigos(idCodigo, values);
                    await getCodigos();
                }
            }
            onSave();

            setCodigoRespuesta('');
            setDescripcion('');
        } else {
            alert('Por favor, complete todos los campos antes de agregar el código de respuesta.');
        }
    };

    const updateCodigoRespuesta = async (value) => {
        setCodigoRespuesta(value)
    }
    const updateDescripcion = async (value) => {
        setDescripcion(value)
    }

    return (
        { updateDescripcion, updateCodigoRespuesta, handleSubmit, codigoRespuesta, descripcion }
    )
}