import '../../../../App.css'
import Tooltip from '@mui/material/Tooltip';
import { useFormularioDetalle } from './useFormularioDetalle.js';

const FormularioDetalle = ({ onSave, onCancel, detalleComision, comision, render }) => {

    const { updateTipoComision, updateLimiteSuperior, updateHacienda, updateLimiteInferior,
        updateMontoComision, handleSubmit, opciones, tipoComision, limiteSuperior, hacienda, limiteInferior,
        montoComision } = useFormularioDetalle(onSave, onCancel, detalleComision, comision, render);

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Tipo de Comisión:
                    </label>
                    <Tooltip title="Tipo de comisión">
                        <select className='select-form' value={tipoComision} onChange={(e) => updateTipoComision(e.target.value)} disabled={render === 'View' ? true : null} >
                            <option value="">Seleccione el tipo de comisión</option>
                            {opciones.map((opcion) => (
                                <option key={opcion.idTipComision} value={opcion.idTipComision}>
                                    {opcion.nomComision}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Limite Inferior:
                    </label>
                    <Tooltip title="Limite Inferior">
                        <input className='input-form' type="text" value={limiteInferior} onChange={(e) => updateLimiteInferior(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Limite Superior:
                    </label>
                    <Tooltip title="Limite Superior">
                        <input className='input-form' type="text" value={limiteSuperior} onChange={(e) => updateLimiteSuperior(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Monto Comisión:
                    </label>
                    <Tooltip title="Monto Comisión">
                        <input className='input-form' type="text" value={montoComision} onChange={(e) => updateMontoComision(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Hacienda:
                    </label>
                    <Tooltip title="Hacienda">
                        <input className='checkbox-form' type="checkbox" checked={hacienda} onChange={() => updateHacienda(!hacienda)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    {render !== 'View' && (
                        <Tooltip title="Guardar">
                            <button id="btn-accion" className="action-button">Guardar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { FormularioDetalle };