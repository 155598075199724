import { useSelector } from "react-redux";
import { useConectividadApi } from "./service";

export const useDetalleComisionService = () => {
    const { serverData } = useConectividadApi();
    const session = useSelector(state => state.session);

    /**
   * @methodApi get.
   * @description  Método encargado de obtener una lista de detalles sobre una comision
   */
    const get = async () => {
        try {
            const options = {
                method: 'GET',
                url: '/comisiones',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    /**
       * @methodApi getTipoComision.
       * @description  Método encargado de obtener una lista de los tipos de comision
       */
    const getTipoComision = async () => {
        try {
            const options = {
                method: 'GET',
                url: '/comisiones/detalle',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
           * @methodApi getTipoComision.
           * @description  Método encargado de obtener la lista de los detalle de comision
           */
    const getComisionTipo = async (idFacturador) => {
        try {
            const options = {
                method: 'GET',
                url: `/comisiones/detalle/${idFacturador}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                }
            };
            return await serverData(options).then(response => {
                let data = response;
                if (data) {
                    return data;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    /**
* @methodApi post.
* @description  Método encargado de crear detalles sobre una comision
*/
    const post = async (values) => {
        try {
            const options = {
                method: 'POST',
                url: '/comisiones/detalle',
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
            };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }
    /**
    * @methodApi v.
    * @description  Método encargado de actualizar detalles sobre una comision
    */
    const put = async (detalleComisionId, values) => {
        try {
            const options = {
                method: 'PUT',
                url: `/comisiones/detalle/${detalleComisionId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
                data: values
            };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }

    /**
* @methodApi v.
* @description  Método encargado de eliminar detalles sobre una comision
*/
    const patch = async (detalleComisionId) => {
        try {
            const options = {
                method: 'PATCH',
                url: `/comisiones/detalle/${detalleComisionId}`,
                headers: {
                    Authorization: `Bearer ${session.credentials.accessToken}`
                },
            };
            try {
                await serverData(options);
            } catch (error) {
                let { data } = error;
                alert(data.msg);
            }
        } catch (error) {
            let { data } = error;
            alert(data.msg);
        }
    }
    return {
        //Api Parametros
        get,
        getTipoComision,
        getComisionTipo,
        post,
        put,
        patch,
    }

}