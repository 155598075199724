//import {useLlavesConsulta } from "./useLlavesConsulta";
import React, { useState } from 'react';
import { DataTable } from '../../Table/Table.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomDialog } from '../../messages/CustomDialog';
import { AlertDialog } from '../../messages/AlertDialog.jsx';
import { successMessages, confirmationMessages } from '../../messages/commonMessages';

const Formulario = ({ onSave, onCancel, selectedRow, isView }) => {
    const [tipoBusqueda, setTipoBusqueda] = useState(selectedRow !== '' ? selectedRow[0].tipoBusqueda : '');
    const [tipoLlave, setTipoLlave] = useState(selectedRow !== '' ? selectedRow[0].tipoLlave : '');
    const [codigoInterno, setCodigoInterno] = useState(selectedRow !== '' ? selectedRow[0].codigoInterno : '');
    const [descripcion, setDescripcion] = useState(selectedRow !== '' ? selectedRow[0].descripcion : '');


    const handleSubmit = (e) => {
        e.preventDefault();

        if (tipoBusqueda && descripcion && tipoLlave && codigoInterno) {

            alert(successMessages.OPERATION_SUCCESS);
            setTipoBusqueda('');
            setTipoLlave('');
            setCodigoInterno('');
            setDescripcion('');
        } else {
            alert('Por favor, complete todos los campos antes de agregar el convenio de recaudadores.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Tipo de Busqueda:
                        <input className='input-form' type="text" value={tipoBusqueda} onChange={(e) => setTipoBusqueda(e.target.value)} disabled={isView ? true : null} />
                    </label>
                </div>
                <div className="columna-form">
                    <label>
                        Tipo Llave:
                        <input className='input-form' type="text" value={tipoLlave} onChange={(e) => setTipoLlave(e.target.value)} disabled={isView ? true : null} />
                    </label>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Código Interno:
                        <input className='input-form' type="text" value={codigoInterno} onChange={(e) => setCodigoInterno(e.target.value)} disabled={isView ? true : null} />
                    </label>
                </div>

                <div className="columna-form">
                    <label>
                        Descripción:
                        <input className='input-form' type="text" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} disabled={isView ? true : null} />
                    </label>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    {!isView && (
                        <button className="action-button" onClick={onSave}>Guardar</button>
                    )}
                    <button className="action-button" onClick={onCancel}>Cancelar</button>
                </div>
            </div>
        </form>
    );
};



const MantenimientoLlavesConsulta = () => {
    const [tipoRecaudador, setTipoRecaudador] = useState('');
    const [selectedRows, setSelectedRows] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();

    const columnas = [
        { field: 'tipoBusqueda', headerName: 'Tipo Búsqueda', width: 160, flex: 1 },
        { field: 'tipoLlave', headerName: 'Tipo Llave', width: 160, flex: 1 },
        { field: 'codigoInterno', headerName: 'Código Interno', width: 160, flex: 1 },
        { field: 'descripcion', headerName: 'Descripción', width: 160, flex: 1 },
        {
            field: 'accion', headerName: 'Acciones', minWidth: 250, flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <button className="action-button" onClick={handleConsult}>
                        <SearchIcon />
                    </button>
                    <button className="action-button" onClick={handleEdit}>
                        <EditIcon />
                    </button>
                    <button className="action-button" onClick={handleDelete}>
                        <DeleteIcon />
                    </button>
                </div>
            ),
        },
    ];

    const rows = [
        { id: 1, tipoBusqueda: '001', tipoLlave: '000', codigoInterno: '1', descripcion: 'Cédula', },
        { id: 2, tipoBusqueda: '002', tipoLlave: 'TEL', codigoInterno: '2', descripcion: 'CONSULTA TELEFONO', },
        { id: 3, tipoBusqueda: '002', tipoLlave: '000', codigoInterno: '3', descripcion: 'Número patronal', },
        { id: 4, tipoBusqueda: '004', tipoLlave: '048', codigoInterno: '4', descripcion: 'Número de abonado', },
        { id: 5, tipoBusqueda: '005', tipoLlave: 'PRI', codigoInterno: '5', descripcion: 'BN2', },
    ];


    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRows('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleSave = () => {
        alert(successMessages.CHANGES_SAVED);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
    };

    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDelete(true);
        setIsDialogOpen(true);
    };

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRows = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
        console.log(selectedRows[0].descripcion);
    };


    return (
        <div className='content-wrapper'>
            <div className="wrapper-card">
                    <h1 style={{ color: '#4b516a' }}>
                        Mantenimiento Llaves de Consulta
                    </h1>
                    <div className='form-container'>
                        <div className="content">
                            <div className='form-container'>
                                <div className="title">
                                    Parámetros
                                </div>
                                <div className="content">
                                    <div className="fila-form">
                                        <div className="columna-form">
                                            <label>
                                                Recaudador:
                                                <select className='select-form' value={tipoRecaudador} onChange={(e) => setTipoRecaudador(e.target.value)}>
                                                    <option value="">Seleccione el recaudador</option>
                                                    <option value="moneda1">CCSS</option>
                                                    <option value="moneda2">RACSA</option>
                                                    <option value="moneda3">JASEC</option>
                                                    <option value="moneda4">FACTURADOR</option>
                                                </select>
                                            </label>
                                        </div>
                                        <label> </label>
                                        <div className="columna-form">
                                            <label>
                                                Convenio:
                                                <select className='select-form' value={tipoRecaudador} onChange={(e) => setTipoRecaudador(e.target.value)}>
                                                    <option value="">Seleccione el convenio</option>
                                                    <option value="">Trabajador Independiente</option>
                                                    <option value="moneda1">Asegurado Voluntario</option>
                                                    <option value="moneda3">Planillas</option>
                                                </select>
                                            </label>
                                        </div>
                                        
                                        <div className="columna-form" style={{marginTop: '40px'}}>
                                            <button className="action-button" onClick={handleConsult}>Buscar</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='form-container'>
                                <div className="title">
                                    Lista de Llaves de Consulta
                                </div>
                                <div className="content">
                                    <DataTable data={rows} columns={columnas} onRowSelectionChange={handleRowSelectionChange} />
                                    <div className='button-action'>
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                        {isDialogOpen && isAdd && (
                                            <CustomDialog
                                                question={'Agregar Llave Consulta'}
                                                message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} />}
                                                onClose={handleCloseDialog}
                                            />
                                        )}
                                        {isDialogOpen && isEdit && (
                                            <CustomDialog
                                                question={'Modificar Llave Consulta'}
                                                message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} />}
                                                onClose={handleCloseDialog}
                                            />
                                        )}
                                        {isDialogOpen && isView && (
                                            <CustomDialog
                                                question={'Consultar Llave Consulta'}
                                                message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isView={isView} />}
                                                onClose={handleCloseDialog}
                                            />
                                        )}
                                        {isDialogOpen && isDelete && (
                                            <AlertDialog
                                                question={confirmationMessages.DELETE_CONFIRMATION}
                                                message="Esta acción no se puede deshacer."
                                                isOpen={isDialogOpen}
                                                onClose={handleCancel}
                                                onAccept={handleCancel}
                                                onCancel={handleCancel}
                                            />
                                        )}
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export { MantenimientoLlavesConsulta };