import '../../../../App.css'
import { useFormulario } from './useFormulario.js';
import Tooltip from '@mui/material/Tooltip';

const Formulario = ({ onSave, onCancel, comision, render }) => {
    const { updateDescripcion, updateCodigoInterno, handleSubmit,
        descripcion, codigoInterno } = useFormulario(onSave, onCancel, comision, render);

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Código Interno:
                    </label>
                    <Tooltip title="Código Interno">
                        <input className='input-form' id="input-codigo-interno" type="text" value={codigoInterno} placeholder='Código Interno' onChange={(e) => updateCodigoInterno(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>

                <div className="columna-form">
                    <label>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' id="input-descripcion" type="text" value={descripcion} placeholder='Descripción' onChange={(e) => updateDescripcion(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button id="btn-accion" className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button id="btn-cancelar" className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { Formulario };