import { useState, useEffect } from 'react';
import { useDetalleComisionService } from '../../../../service/detalleComision';
import { errorMessages } from '../../../messages/commonMessages';
import { useDetalleComisiones } from '../useDetalleComisiones';

export const useFormularioDetalle = (onSave, onCancel, detalleComision, comision, render) => {
    const [idComision, setIdComision] = useState(detalleComision ? detalleComision[0].idComision : '');
    const [tipoComision, setTipoComision] = useState(detalleComision ? detalleComision[0].idTipComision : '');
    const [limiteSuperior, setLimiteSuperior] = useState(detalleComision ? detalleComision[0].lmtSuperior : '');
    const [hacienda, setHacienda] = useState(detalleComision ? detalleComision[0].indHacienda : false);
    const [limiteInferior, setLimiteInferior] = useState(detalleComision ? detalleComision[0].lmtInferior : '');
    const [montoComision, setMontoComision] = useState(detalleComision ? detalleComision[0].mtoComision : '');
    const { post, put, getTipoComision } = useDetalleComisionService();
    const [opciones, setOpciones] = useState([]);
    const {getDetallesComisiones } = useDetalleComisiones();

    let values = {};
    const obtenerTipoComisiones = async () => {
        getTipoComision().then((opciones) => {
            setOpciones(opciones);
        })
    }
    useEffect(() => {
        obtenerTipoComisiones();
    }, []);
    const addDetalleComisiones = async (values) => {
        post(values);
    }
    const updateDetalleComisiones = async (idTipoComision, values) => {
        put(idTipoComision, values);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (tipoComision && limiteSuperior && hacienda && limiteInferior && montoComision) {
            values.idComision = idComision;
            values.idTipComision = tipoComision;
            values.lmtSuperior = limiteSuperior;
            values.indHacienda = hacienda;
            values.lmtInferior = limiteInferior;
            values.mtoComision = montoComision;
            values.indActivo = 1;
            if (render === 'Add') {
                values.idComision = comision[0].idComision;
                await addDetalleComisiones(values);
                await getDetallesComisiones(comision[0].idComision);

            } else {
                let idComisionTip = detalleComision[0].idComisionTip
                if (idComisionTip !== undefined) {
                    await updateDetalleComisiones(idComisionTip, values);
                    await getDetallesComisiones(comision[0].idComision);
                }
            }
            onSave();
            setIdComision('');
            setTipoComision('');
            setLimiteSuperior('');
            setHacienda('');
            setLimiteInferior('');
            setMontoComision('');
        } else {
            alert(errorMessages.FORM_ERROR);
        }
    };
    const updateTipoComision = async (value) => {
        setTipoComision(value)
    }
    const updateLimiteSuperior = async (value) => {
        setLimiteSuperior(value)
    }
    const updateHacienda = async (value) => {
        setHacienda(value);
    }
    const updateLimiteInferior = async (value) => {
        setLimiteInferior(value)
    }
    const updateMontoComision = async (value) => {
        setMontoComision(value)
    }
    return (
        {
            updateTipoComision, updateLimiteSuperior, updateHacienda, updateLimiteInferior, updateMontoComision, handleSubmit,
            opciones, tipoComision, limiteSuperior, hacienda, limiteInferior, montoComision
        }
    )
};